<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px">
                                申请编号:
                            </td>
                            <td>
                                <el-input v-model="searchItem.applyNum" style="width: 100%;" size="small"
                                          placeholder="请输入申请编号"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                销售人员:
                            </td>
                            <td>
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                申请标题:
                            </td>
                            <td>
                                <el-input v-model="searchItem.title" style="width: 100%;" size="small"
                                          placeholder="请输入标题"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">
                                申请时间:
                            </td>
                            <td style="padding-top: 10px">
                                <div class="input-date">
                                    <el-date-picker
                                            v-model="searchItem.startDate"
                                            type="date"
                                            size="small"
                                            placeholder="选择日期时间"
                                            style="width: 47%;margin-left: -20%"
                                            class="handle-input-date"
                                            value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <span style="padding: 5px">至</span>
                                    <el-date-picker
                                            v-model="searchItem.endDate"
                                            type="date"
                                            placeholder="选择日期时间"
                                            size="small"
                                            style="width: 47%;"
                                            class="handle-input-date"
                                            value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td colspan="2" style="text-align: center;padding-top: 10px">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                </el-button>
                                <el-button type="primary" style="margin-left: 20%" icon="el-icon-refresh" size="small"
                                           @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                border
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="applyNum" label="申请编号" width="180"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userName" label="销售人员" width="150" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="150" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="title" label="申请标题" width="280" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="content" label="申请内容" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createTime" label="申请时间" width="110" :formatter="dateFormat"
                                             align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="auditState" label="当前状态" width="110" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span style="color: red" v-if="scope.row.auditState == -1">已驳回</span>
                                    <span style="color: green" v-else-if="scope.row.auditState == 10">已通过</span>
                                    <span style="color: blue" v-else>审批中</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="uploadFile" label="上传附件" width="90" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.auditState == 10" type="text"
                                               @click="uploadFiles(scope.row.id)">上传附件
                                    </el-button>
                                    <el-button v-if="scope.row.auditState != 10" type="text" disabled>上传附件</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" width="150" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont iconbohui"
                                           v-if="scope.row.auditState == 1 && hasAuthority('special_approve_audit')"
                                           title="驳回申请"
                                           @click="refuseSpecial(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showSpecial(scope.row.id)"></i>
                                        <i class="iconfont icontongguo"
                                           v-if="scope.row.auditState == 1 && hasAuthority('special_approve_audit')"
                                           title="通过申请"
                                           @click="passSpecial(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="infoTitle"
                    :visible.sync="infoDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 5px;width: 80px">
                                        申请编号：
                                    </td>
                                    <td style="text-align: left">
                                        {{specialDispaly.applyNum}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">销售人员：</td>
                                    <td style="text-align: left">
                                        {{specialDispaly.userName}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">联系方式：</td>
                                    <td style="text-align: left">
                                        {{specialDispaly.userTel}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px;width: 80px">
                                        申请标题：
                                    </td>
                                    <td colspan="5" style="text-align: left">
                                        {{specialDispaly.title}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px;width: 80px">
                                        申请内容：
                                    </td>
                                    <td colspan="5" style="text-align: left">
                                        {{specialDispaly.content}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="file-info">
                        <div class="text-title">
                            附件信息及下载
                        </div>
                        <div class="down-file">
                            <ol>
                                <li v-for="(file,i) in files" class="file-list" :key="i">
                                    <div style="width: 60%">
                                        <a :href="urlFilePath + 'erp' + file.attachmentUrl">{{file.attachmentName}}</a>
                                    </div>
                                    <div style="width: 20%">
                                        <span>{{changeDateFormat(file.createTime)}}</span>
                                    </div>
                                    <div style="width: 10%">
                                        <span>{{file.createUserName}}</span>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <Upload @openDialog="openDialog" :fileId="contractId" :fileType="uploadType"
                :dialogVisibles="uploadVisible"/>
    </div>
</template>


<script>
    var urlPath = process.env.VUE_APP_CURENV;
    export default {

        data() {
            return {
                pageList: [15, 20, 30, 50,],
                tableHeight: '',
                contractId: '',
                uploadType: '',
                uploadVisible: false,
                files: [],
                urlFilePath: urlPath,
                inputWidth: '',
                saleList: [],
                searchItem: {
                    applyNum: '',
                    userName: '',
                    title: '',
                    startDate: '',
                    endDate: '',
                    pageIndex: 1,
                    pageSize: 15
                },
                infoDialogVisible: false,
                option: {
                    pageIndex: 1,
                    pageSize: 15,
                },
                totalPage: 10,
                lineHeight: '41px',
                tableData: [{}],
                infoTitle: '',
                attachmentUrl: '',
                specialDispaly: {
                    applyNum: '',
                    userName: '',
                    title: '',
                    content: '',
                    attachmentUrl: '',
                },
                uploadFiles(id) {
                    this.uploadType = 'special';
                    this.uploadVisible = true;
                    this.contractId = id;
                },
            }
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageSpecialApprove(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageSpecialApprove(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            searchBtu() {
                this.pageChange(1);
            },
            reset() {
                this.searchItem = {
                    applyNum: '',
                    attachmentUrl: '',
                    content: '',
                    createTime: '',
                    title: '',
                    userName: '',
                    pageIndex: 1,
                    pageSize: 15
                }
                this.pageChange(1);
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(date) {
                let dt = new Date(date)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            showSpecial(id) {
                this.infoDialogVisible = true;
                this.$api.pageSpecialApproveById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.specialDispaly = {
                            applyNum: res.data.applyNum,
                            attachmentUrl: res.data.attachmentUrl,
                            content: res.data.content,
                            createTime: res.data.createTime,
                            title: res.data.title,
                            userName: res.data.userName,
                            userTel: res.data.userTel,
                        }
                        if (res.data.attachmentUrl == null || res.data.attachmentUrl == undefined || res.data.attachmentUrl == '') {
                            this.attachmentUrl = ''
                        } else {
                            this.attachmentUrl = res.data.attachmentUrl
                        }
                        this.files = res.data.attachmentList;
                        this.infoTitle = '《' + res.data.applyNum + '》详细信息'
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            passSpecial(id) {
                this.$confirm('你确定要通过该特殊申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.approveSpecialApproval({id: id, isPass: 1}).then(
                        res => {
                            if (res.code == 200) {
                                this.pageChange("refresh")
                                this.$message.success(res.message);
                            } else {
                                this.pageChange("refresh")
                                this.$message.error(res.message);
                            }
                        }
                    )
                }).catch(() => {
                })
            },
            openDialog(val) {
                this.uploadVisible = !this.uploadVisible;
            },
            refuseSpecial(id) {
                this.$confirm('你确定要驳回该特殊申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.approveSpecialApproval({id: id, isPass: 0}).then(
                        res => {
                            if (res.code == 200) {
                                this.pageChange("refresh")
                                this.$message.success(res.message);
                            } else {
                                this.pageChange("refresh")
                                this.$message.error(res.message);
                            }
                        }
                    )
                }).catch(() => {
                })
            },
        },
        created() {
            this.tableHeight = (window.innerHeight) * 0.71
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
            this.searchBtu()
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        overflow-y: auto;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    .iconbohui {
        color: red;
        margin-right: 20px;
    }

    .icontongguo {
        color: green;
        margin-left: 20px;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-info {
        height: 87%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
        margin-top: 10px;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    table {
        table-layout: fixed;
    }

    td {
        text-align: right;
    }

    .input-date {
        height: 100%;
        width: 100%;
    }

    .file-info {
        margin-top: 50px;
    }

    .file-list {
        width: 95%;
        display: flex;
        margin-left: 10px;
    }

    el-date-picker {
        padding: 0 !important;
        margin: 0 !important;
    }
</style>